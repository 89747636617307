import React from 'react'
import { T, NVInput } from '@nv/react-commons/src/Components'
import { StyledFormItem, StyledTextArea } from 'components/OCForm/styles'
import { RedAsterisk } from 'containers/FPLRegistration/styles'
import { ValidatorUtils } from '@nv/react-commons/src/Utils'
import { EMAIL_REGEX, FLOAT_NUMBER_REGEX, NUMBER_REGEX, PHONE_REGEX } from 'containers/FPLOrderCreate/dataUtils'
import { StyledMultipleText } from './styles'
import { FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000, FLOAT_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000, DEFAULT_DIMENSION_UNIT } from './constants';
import { NUMBER_1000, NUMBER_255, NUMBER_999, requestedCartonsTrackingIDsValidator, requestedTrackingIdB2BBundleValidator } from './validatorUtils'
import { upperCase } from 'lodash'

export const CustomerContact = ({ form: { getFieldDecorator }, intl }) => {
  const rules = [
    {
      required: true,
      message: intl.formatMessage({
        id: 'customer_contact_required'
      })
    },
    {
      pattern: PHONE_REGEX,
      message: intl.formatMessage({ id: 'international_conact_number_must_be_valid_format' })
    }
  ]
  return (
    <StyledFormItem
      label={
        <span>
          <T id='customer_contact' />
          <RedAsterisk />
        </span>
      }
    >
      {getFieldDecorator('contact', { rules })(<NVInput />)}
    </StyledFormItem>
  )
}

export const CustomerName = ({ form: { getFieldDecorator }, intl }) => (
  <StyledFormItem
    label={
      <span>
        <T id='customer_name' />
        <RedAsterisk />
      </span>
    }
  >
    {getFieldDecorator('name', {
      rules: [
        {
          required: true,
          message: intl.formatMessage({
            id: 'customer_name_required'
          })
        },
        {
          max: NUMBER_255,
          message: intl.formatMessage({
            id: 'customer_name_restriction'
          })
        }
      ]
    })(<NVInput />)}
  </StyledFormItem>
)

export const CustomerEmail = ({ form: { getFieldDecorator }, intl }) => (
  <StyledFormItem label={<T id='customer_email' />}>
    {getFieldDecorator('email', {
      rules: [
        {
          pattern: EMAIL_REGEX,
          message: intl.formatMessage({ id: 'invalid_email' })
        }
      ]
    })(<NVInput placeholder={intl.formatMessage({ id: 'optional' })} />)}
  </StyledFormItem>
)

export const CartonsNumber = ({ form: { getFieldDecorator, getFieldError }, intl }) => {
  const errorMessages = getFieldError('numberOfCartons') || []
  return (
    <StyledFormItem
      label={
        <span>
          <T id='no_of_cartons' />
          <RedAsterisk />
        </span>
      }
      help={errorMessages.map((item) => <div key={item}>{item}</div>)}
    >
      {getFieldDecorator('numberOfCartons', {
        rules: [
          {
            required: true,
            message: intl.formatMessage({ id: 'number_of_carton_required' })
          },
          {
            validator: ValidatorUtils.number(
              intl.formatMessage({ id: 'invalid_number_of_carton' }, { x: NUMBER_999 }),
              value => value > NUMBER_999
            )
          },
          {
            validator: (_, value, callback) => {
              if (value && value < 1) {
                callback(intl.formatMessage({ id: 'the_value_must_be_number_greate_than_1' }))
              } else {
                callback()
              }
            }
          },
          {
            pattern: NUMBER_REGEX,
            message: intl.formatMessage({ id: 'positive_number_of_cartons' })
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}

export const TotalCartonValue = ({ form: { getFieldDecorator, getFieldError }, intl, customsCurrency }) => {

  return (
    <StyledFormItem
      label={
        <span>
          <T id='total_carton_value_with_unit' values={{ x: upperCase(customsCurrency) }} />
          <RedAsterisk />
        </span>
      }
    >
      {getFieldDecorator('totalCartonValue', {
        rules: [
          {
            required: true,
            message: intl.formatMessage({ id: 'total_carton_value_required' })
          },
          {
            validator: (_, value, callback) => {
              if (value && value <= 0) {
                callback(intl.formatMessage({ id: 'the_value_must_be_number_greate_than_0' }));
              } else {
                callback();
              }
            }
          },
          {
            pattern: FLOAT_NUMBER_REGEX,
            message: intl.formatMessage({ id: 'the_value_must_be_number_greate_than_0' })
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}

export const ShipperOrderNumber = ({ form: { getFieldDecorator }, intl }) => {
  return (
    <StyledFormItem label={<T id='order_ref_no' />}>
      {getFieldDecorator('shipperOrderNumber', {
        rules: [
          {
            max: NUMBER_255,
            message: intl.formatMessage({ id: 'order_ref_no_invalid' }, { x: NUMBER_255 })
          }
        ]
      })
        (<NVInput placeholder={intl.formatMessage({ id: 'optional' })} />)}
    </StyledFormItem>
  )
}

export const Weight = ({ form: { getFieldDecorator }, intl }) => {
  return (
    <StyledFormItem label={<T id='weight_kg' />}>
      {getFieldDecorator('weight', {
        rules: [
          {
            pattern: FLOAT_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000,
            message: intl.formatMessage({ id: 'the_value_must_be_number_greate_than_0' })
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}

export const Length = ({ form: { getFieldDecorator }, intl }) => {
  return (
    <StyledFormItem label={<T id='length_in_unit' values={{ x: DEFAULT_DIMENSION_UNIT }} />}>
      {getFieldDecorator('length', {
        rules: [
          {
            pattern: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
            message: intl.formatMessage({ id: 'number_must_be_number_between_1_and_1000' })
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}

export const Width = ({ form: { getFieldDecorator }, intl }) => {
  return (
    <StyledFormItem label={<T id='width_in_unit' values={{ x: DEFAULT_DIMENSION_UNIT }} />}>
      {getFieldDecorator('width', {
        rules: [
          {
            pattern: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
            message: intl.formatMessage({ id: 'number_must_be_number_between_1_and_1000' })
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}

export const Height = ({ form: { getFieldDecorator }, intl }) => {
  return (
    <StyledFormItem label={<T id='height_in_unit' values={{ x: DEFAULT_DIMENSION_UNIT }} />}>
      {getFieldDecorator('height', {
        rules: [
          {
            pattern: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
            message: intl.formatMessage({ id: 'number_must_be_number_between_1_and_1000' })
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}

export const CartonDescription = ({ form: { getFieldDecorator }, intl }) => {
  return (
    <StyledFormItem
      label={
        <span>
          <T id='carton_description' />
          <RedAsterisk />
        </span>
      }
    >
      {getFieldDecorator('description', {
        rules: [
          {
            required: true,
            message: intl.formatMessage({ id: 'carton_description_required' })
          },
          {
            max: NUMBER_1000,
            message: intl.formatMessage({
              id: 'the_description_must_be_maximum_1000_characters'
            })
          }
        ]
      })(
        <StyledMultipleText
          placeholder={intl.formatMessage({
            id: 'carton_description_placeholder'
          })}
          autoSize={{ minRows: 3 }}
          style={{ maxHeight: 200, height: 81 }}
        />
      )}
    </StyledFormItem>
  )
}

export const HsCode = ({ form: { getFieldDecorator }, intl }) => {
  return (
    <StyledFormItem label={<T id='hs_codes' />}>
      {getFieldDecorator('hsCode', {
        rules: [
          {
            max: NUMBER_1000,
            message: intl.formatMessage({
              id: 'hs_code_must_be_maximum_characters'
            }, { x: NUMBER_1000 })
          }
        ]
      })(
        <StyledMultipleText
          placeholder={intl.formatMessage({
            id: 'hs_codes_placeholder'
          })}
          autoSize={{ minRows: 3 }}
        />
      )}
      <div style={{ fontSize: 10, lineHeight: 'normal', textAlign: 'end' }}>
        <T id='seperate_hs_code_note_in_shortly' />
      </div>
    </StyledFormItem>
  )
}

export const AddressLine1 = ({ form: { getFieldDecorator }, intl }) => {
  return (
    <StyledFormItem
      label={
        <span>
          <T id='address_line_1' />
          <RedAsterisk />
        </span>
      }
    >
      {getFieldDecorator('addressLine1', {
        rules: [
          {
            required: true,
            message: `${intl.formatMessage({ id: 'address_line_1_required' })}`
          },
          {
            max: NUMBER_255,
            message: intl.formatMessage({ id: 'address_restriction' })
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}

export const AddressLine2 = ({ form: { getFieldDecorator }, intl }) => {
  return (
    <StyledFormItem label={<T id='address_line_2' />}>
      {getFieldDecorator('addressLine2', {
        rules: [
          {
            max: NUMBER_255,
            message: intl.formatMessage({ id: 'address_restriction' })
          }
        ]
      })(<NVInput />)}
    </StyledFormItem>
  )
}

export const RequestedCartonTIDs = ({ form: { getFieldDecorator, getFieldValue }, intl, bundles }) => {
  return (
    <StyledFormItem label={<T id='requested_carton_tracking_id' />}>
      {getFieldDecorator('requestedCartonTrackingNumbers', {
        rules: [{
          validator: (_, value, callback) => {
            const numberOfCartons = getFieldValue('numberOfCartons')
            const error = requestedCartonsTrackingIDsValidator(value, numberOfCartons, bundles)
            if (error !== null) {
              callback(intl.formatMessage({ id: error.id }, { ...error.values }))
            } else {
              callback()
            }
          }
        }]
      })(
        <StyledTextArea
          placeholder={intl.formatMessage({
            id: 'requested_piece_tracking_ids_placeholder'
          })}
          autoSize={{ minRows: 3 }}
        />
      )}
    </StyledFormItem>
  )
}

export const RequestedTrackingID = ({ form: { getFieldDecorator }, intl, bundles }) => {
  return (
    <StyledFormItem
      label={<T id='international_requested_tracking_id' />}
    >
      {getFieldDecorator('requestedTrackingID', {
        rules: [
          {
            validator: (_, value, callback) => {
              const error = requestedTrackingIdB2BBundleValidator(value, bundles)
              if (error !== null) {
                callback(intl.formatMessage({ id: error.id }, { ...error.values }))
              } else {
                callback()
              }
            }
          }
        ]
      })(
        <NVInput />
      )}
    </StyledFormItem>
  )
}
