import { CsvUtils, ExcelUtils } from '@nv/react-commons/src/Utils'
import { ERROR_CODE_MAPPING, RULE_TYPES, SERVICE_TYPE } from '../FPLOrderCreate/constants'
import { PICKUP_TYPES } from '../PickupType/constants'
import {
  B2BBoxDetail,
  B2CBoxDetail,
  Dimension,
  MappedDataKeys,
  RowValueObject,
  UploadedBoxOrder,
  ValidationProps,
  B2BBundleOrderFormGroup
} from './types'
import { isValidHeader, PHONE_REGEX } from '../FPLOrderCreate/dataUtils'
import { COLUMN_TRUNCATION_MAP, ERRORS_TRANSLATION_KEYS, MMCC_VALIDATION_RULES, VALIDATORS } from './validationRules'
import { DELIVERY_TIME_PARAMS } from 'containers/FPLOrderRequestList/constants'
import { UPLOAD_CSV_OPTION } from 'containers/FPLAllOrders/constants'
import { formatNumber } from 'containers/FPLParcelDetail/dataUtils'
import {
  B2B_FIELD_KEYS,
  B2B_INSTRUCTION_DATA,
  B2C_FIELD_KEYS,
  B2C_INSTRUCTION_DATA,
  FLOAT_NUMBER_AND_GREATER_THAN_0,
  FLOAT_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000,
  MMCC_B2B_EXAMPLE_1,
  MMCC_B2B_EXAMPLE_2,
  MMCC_KEYS,
  MMCC_KEYS_TRANSLATOR_FROM_CODE_TO_FRIENTLY_NAME,
  MMCC_KEYS_TRANSLATOR_FROM_FRIENDLY_NAME_TO_CODE,
  MMCC_ROW_EXAMPLE_1,
  MMCC_ROW_EXAMPLE_2,
  MMCC_ROW_EXAMPLE_3,
  MMCC_ROW_EXAMPLE_4,
  MMCC_ROW_EXAMPLE_5,
  MMCC_ROW_EXAMPLE_6,
  MMCC_ROW_EXAMPLE_7,
  MMCC_ROW_EXAMPLE_8,
  MMCC_ROW_EXAMPLE_9,
  MMCC_ROW_EXAMPLE_10,
  RECIPIENT_COMPARATION_KEYS,
  FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
  INTEGER_NUMBER_AND_GREATER_THAN_0,
  BUNDLE_REQUIRED_OPTIONS,
  DEFAULT_DIMENSION_UNIT
} from './constants'
import { COUNTRIES } from '@nv/react-commons/src/Constants'
import {
  isRequiredCity,
  isRequiredPostCode,
  MAX_UPLOAD_ORDER_REQUEST,
  NUMBER_1000,
  NUMBER_OF_USELESS_LINE,
  postcodeDigitsByCountry,
  requestedCartonsTrackingIDsValidator,
  requestedTrackingIdB2BBundleValidator,
  validateAddress1,
  validateAddress2,
  validateCity,
  validateContact,
  validateEmail,
  validateName,
  validateNumberOfCartons,
  validateOptionalAndFollowingPatternNumber,
  validateOptionalAndLengthOfValue,
  validatePostcode,
  validateRequiredAndLengthOfValue,
  validateShipperOrderNumber,
  validateTotalCartonValues
} from './validatorUtils'
import { endsWith, groupBy, isEmpty, lowerCase, map, round, toLower, toNumber, toUpper, trim, uniq, uniqBy } from 'lodash'

export const generateTemplate = (serviceType: number, locale: string, intl) => {
  const isB2C = serviceType === SERVICE_TYPE.MMCCB2C
  const instructionFields = isB2C ? B2C_INSTRUCTION_DATA : B2B_INSTRUCTION_DATA
  const headerFields = Object.keys(instructionFields).map(key => {
    if (MMCC_KEYS_TRANSLATOR_FROM_CODE_TO_FRIENTLY_NAME[locale]) {
      return MMCC_KEYS_TRANSLATOR_FROM_CODE_TO_FRIENTLY_NAME[locale][key]
    }
    return MMCC_KEYS_TRANSLATOR_FROM_CODE_TO_FRIENTLY_NAME['en'][key]
  })
  const translatorInstructions = Object.values(instructionFields).map(key => intl.formatMessage({ id: key }))
  const exampleData = isB2C
    ? [
      MMCC_ROW_EXAMPLE_1,
      MMCC_ROW_EXAMPLE_2,
      MMCC_ROW_EXAMPLE_3,
      MMCC_ROW_EXAMPLE_4,
      MMCC_ROW_EXAMPLE_5,
      MMCC_ROW_EXAMPLE_6,
      MMCC_ROW_EXAMPLE_7,
      MMCC_ROW_EXAMPLE_8,
      MMCC_ROW_EXAMPLE_9,
      MMCC_ROW_EXAMPLE_10
    ]
    : [MMCC_B2B_EXAMPLE_1, MMCC_B2B_EXAMPLE_2]

  const dataFields = [translatorInstructions, ...exampleData.map(example => Object.values(example))]
  return {
    headerFields: [...headerFields],
    dataFields
  }
}

export const verifyFileExtensionAndTemplate = async (file, serviceType, locale) => {
  const fileName = file?.name
  let results = { data: [], errors: [] }
  const errorMsg = []
  if (endsWith(fileName, '.csv')) {
    results = await CsvUtils.parse(file, UPLOAD_CSV_OPTION)
  }
  if (endsWith(fileName, '.xlsx') || endsWith(fileName, '.xls')) {
    results.data = await ExcelUtils.parseFile(file)
  }
  if (results.errors && results.errors.length !== 0) {
    errorMsg.push({ id: 'international_advanced_search_broken_file' })
  }
  if (results.data) {
    const headerFields = (results.data[0] || []).map(key => {
      if (MMCC_KEYS_TRANSLATOR_FROM_FRIENDLY_NAME_TO_CODE[locale]) {
        return MMCC_KEYS_TRANSLATOR_FROM_FRIENDLY_NAME_TO_CODE[locale][key]
      }
      return MMCC_KEYS_TRANSLATOR_FROM_FRIENDLY_NAME_TO_CODE['en'][key]
    })
    const isWrongTemplate = verifyTemplate(headerFields, serviceType)
    if (isWrongTemplate) {
      errorMsg.push({ id: 'international_order_creation_wrong_template' })
    }

    if (results.data.length <= NUMBER_OF_USELESS_LINE) {
      errorMsg.push({ id: 'international_empty_order' })
    }

    if (results.data.length - NUMBER_OF_USELESS_LINE > MAX_UPLOAD_ORDER_REQUEST) {
      errorMsg.push({
        id: 'international_mmcc_maximum_request_row',
        values: { x: formatNumber(MAX_UPLOAD_ORDER_REQUEST) }
      })
    }
    results.data[0] = headerFields
  }
  return {
    fileData: results,
    errorMsg,
    fileName
  }
}

const verifyTemplate = (headerFields, serviceType) => {
  const parcelHeaderKeys = Object.keys(
    serviceType == SERVICE_TYPE.MMCCB2C ? B2C_INSTRUCTION_DATA : B2B_INSTRUCTION_DATA
  )
  const isValidHeaderLength = headerFields.length === parcelHeaderKeys.length
  const isValidHeaderName = isValidHeader(headerFields, parcelHeaderKeys)
  return !isValidHeaderLength || !isValidHeaderName
}

const groupRows = (allOrdersByKey, groupField) => {
  return Object.keys(allOrdersByKey).reduce((results, indexKey) => {
    const fieldValue = allOrdersByKey[indexKey][groupField].value || indexKey
    results[fieldValue] = results[fieldValue] || []
    results[fieldValue].push(indexKey)
    return results
  }, {})
}

const truncateValue = (data, fieldsToTruncate) => {
  return data.map(item => {
    return Object.keys(item).reduce((acc, key) => {
      const trimmedValue = item[key].trim()
      acc[key] =
        fieldsToTruncate[key] && trimmedValue.length > fieldsToTruncate[key]
          ? trimmedValue.substring(0, fieldsToTruncate[key])
          : trimmedValue
      return acc
    }, {})
  })
}

const compareIndices = (data, keys: string[], boxId: string) => {
  keys.forEach(key => {
    const values = Object.values(data).map(row => row[key]?.value)
    if (uniq(values).length !== 1) {
      Object.values(data).forEach(d => {
        d[key].errors.push({ key: 'mismatch_recipient_address', values: [{ x: boxId }] })
      })
    }
  })
  return data
}

const areAllRowsValid = rows => {
  return Object.values(rows).every(row => Object.values(row).every(field => !field.errors.length))
}

const processB2CRows = (allRowsByIndex: { [key: string]: RowValueObject }) => {
  const groupsByBoxId = groupRows(allRowsByIndex, MMCC_KEYS.BOX_ID)
  const validItemisedOrders = {},
    invalidItemisedOrders = {},
    invalidRows = {},
    validRows = {}
  let numberOfValidItemsInValidOrders = 0,
    numberOfValidItemsInInvalidOrders = 0,
    numberOfInvalidItemsInInvalidOrders = 0

  Object.keys(groupsByBoxId).forEach(boxId => {
    let oneGroup = groupsByBoxId[boxId].reduce((prevRow, currentRowIdx) => {
      return {
        ...prevRow,
        [currentRowIdx]: allRowsByIndex[currentRowIdx]
      }
    }, {})

    let isValid = areAllRowsValid(oneGroup)
    if (isValid) {
      oneGroup = compareIndices(oneGroup, RECIPIENT_COMPARATION_KEYS, boxId)
      isValid = areAllRowsValid(oneGroup)
    }

    if (isValid) {
      validItemisedOrders[boxId] = oneGroup
      numberOfValidItemsInValidOrders += Object.keys(oneGroup).length
      Object.keys(oneGroup).forEach(rowIndex => {
        validRows[rowIndex] = oneGroup[rowIndex]
      })
    } else {
      Object.keys(oneGroup).forEach(rowIndex => {
        if (isValid) {
          numberOfValidItemsInInvalidOrders += 1
          validRows[rowIndex] = oneGroup[rowIndex]
        } else {
          numberOfInvalidItemsInInvalidOrders += 1
          invalidRows[rowIndex] = oneGroup[rowIndex]
        }
      })
      invalidItemisedOrders[boxId] = oneGroup
    }
  })

  return {
    validRows,
    invalidRows,
    validItemisedOrders,
    invalidItemisedOrders,
    numberOfValidItemsInValidOrders,
    numberOfValidItemsInInvalidOrders,
    numberOfInvalidItemsInInvalidOrders
  }
}

const mapRowData = (rows, constantKeys) => {
  return rows.slice(NUMBER_OF_USELESS_LINE).map(row => {
    const rowData: Partial<MappedDataKeys> = {}
    constantKeys.forEach((columnKey: string, index: number) => {
      rowData[columnKey] = row[index] || ''
    })
    return rowData
  })
}

const truncateMappedData = (mappedData: any[]) => {
  return truncateValue(mappedData, COLUMN_TRUNCATION_MAP)
}

const validateRequiredField = (
  columnValue: any,
  isRequired: boolean,
  columnKey: string,
  serviceType: number,
  fieldErrors: { key: string; values?: [] }[]
) => {
  const isEmptyValue = !columnValue && columnValue !== 0
  if (isRequired && isEmptyValue) {
    if (
      serviceType == SERVICE_TYPE.MMCCB2C ||
      (serviceType == SERVICE_TYPE.MMCCB2B && columnKey !== MMCC_KEYS.BOX_ID)
    ) {
      fieldErrors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.REQUIRED])
    }
  }
}

const applyValidationRules = (
  rules: any[],
  columnValue: any,
  columnKey: string,
  country: string,
  fieldErrors: { key: string; values?: [] }[]
) => {
  rules.forEach(rule => {
    const { type, validator } = rule
    switch (type) {
      case RULE_TYPES.MIN_LENGTH:
        if (columnValue?.length <= validator) {
          fieldErrors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
      case RULE_TYPES.MAX_LENGTH:
        if (columnValue?.length >= validator) {
          fieldErrors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
      case RULE_TYPES.EQUAL_MAX_LENGTH:
        if (columnValue?.length > validator) {
          fieldErrors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
      case RULE_TYPES.REGEX:
        if (columnKey === MMCC_KEYS.RECIPIENT_POSTCODE && validator[country]) {
          const countrySpecificValidator = validator[country]
          if (!countrySpecificValidator?.test(columnValue)) {
            fieldErrors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
          }
        } else if (!validator.test(columnValue)) {
          fieldErrors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
      case RULE_TYPES.EQUAL_MIN:
        if (columnValue === '' || toNumber(columnValue) <= validator) {
          fieldErrors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
      case RULE_TYPES.MAX:
        if (columnValue === '' || toNumber(columnValue) >= validator) {
          fieldErrors.push(ERRORS_TRANSLATION_KEYS[columnKey][ERROR_CODE_MAPPING.INVALID])
        }
        break
    }
  })
}

const validateRow = (row: any, country: string, serviceType: number): RowValueObject => {
  const rowValueObject: RowValueObject = {}
  Object.keys(row).forEach(columnKey => {
    const { required, requiredByCountry, rules } = MMCC_VALIDATION_RULES[columnKey]
    const columnValue = row[columnKey]
    const fieldErrors: { key: string; values?: [] }[] = []

    const isRequired = required || (requiredByCountry && requiredByCountry.includes(country))

    validateRequiredField(columnValue, isRequired, columnKey, serviceType, fieldErrors)
    if (isRequired || columnValue) {
      applyValidationRules(rules, columnValue, columnKey, country, fieldErrors)
    }

    rowValueObject[columnKey] = {
      value: columnValue,
      errors: uniqBy(fieldErrors, error => error.key)
    }
  })

  return rowValueObject
}

export const validateUploadedData = ({ rows, serviceType, country }: ValidationProps) => {
  const constantKeys = serviceType == SERVICE_TYPE.MMCCB2C ? B2C_FIELD_KEYS : B2B_FIELD_KEYS
  const mappedData = mapRowData(rows, constantKeys)
  const mmccOrderMapped = truncateMappedData(mappedData)

  const allRowsByKey: { [key: string]: RowValueObject } = {},
    validRows: { [key: string]: RowValueObject } = {},
    invalidRows: { [key: string]: RowValueObject } = {}

  mmccOrderMapped.forEach((row, rowIndex) => {
    const rowValueObject = validateRow(row, country, serviceType)

    const firstRowError = Object.values(rowValueObject).find(rowField => rowField.errors.length > 0)
    if (firstRowError) {
      invalidRows[rowIndex + NUMBER_OF_USELESS_LINE] = rowValueObject
    } else {
      validRows[rowIndex + NUMBER_OF_USELESS_LINE] = rowValueObject
    }
    allRowsByKey[rowIndex + NUMBER_OF_USELESS_LINE] = rowValueObject
  })

  let result = {
    allOrders: allRowsByKey,
    validRows: validRows,
    invalidRows: invalidRows,
    invalidItemisedOrders: invalidRows,
    validItemisedOrders: validRows,
    numberOfValidItemsInValidOrders: null,
    numberOfValidItemsInInvalidOrders: null,
    numberOfInvalidItemsInInvalidOrders: null
  }

  if (serviceType == SERVICE_TYPE.MMCCB2C) {
    const {
      validItemisedOrders,
      invalidItemisedOrders,
      numberOfValidItemsInValidOrders,
      numberOfValidItemsInInvalidOrders,
      numberOfInvalidItemsInInvalidOrders,
      ...rest
    } = processB2CRows(allRowsByKey)
    result = {
      ...result,
      validRows: rest.validRows,
      invalidRows: rest.invalidRows,
      invalidItemisedOrders,
      validItemisedOrders,
      numberOfValidItemsInValidOrders,
      numberOfValidItemsInInvalidOrders,
      numberOfInvalidItemsInInvalidOrders
    }
  }

  return result
}

export const makeFriendlyError2DArray = (data, intl) => {
  return map(data, row =>
    map(row, item => {
      const formattedErrors = map(item.errors, error => {
        const values = (error.values || []).reduce((err, current) => {
          return { ...err, ...current }
        }, {})
        return intl.formatMessage({ id: error.key }, values)
      })
      return {
        value: item.value,
        errors: formattedErrors
      }
    })
  )
}

export const buildBoxesForB2COrder = (orders: UploadedBoxOrder, currency: string, country: string): B2CBoxDetail[] => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return Object.values(orders).map((box, index) => {
    const parcelGroupsByTID = groupBy(box, item => item[MMCC_KEYS.PARCEL_TRACKING_ID]?.value)
    const parcelList = Object.keys(parcelGroupsByTID).map(tid => {
      const items = parcelGroupsByTID[tid].map(parcel => {
        return {
          quantity: toNumber(parcel[MMCC_KEYS.ITEM_QUANTITY].value),
          description: parcel[MMCC_KEYS.ITEM_DESCRIPTION].value,
          value: toNumber(parcel[MMCC_KEYS.ITEM_VALUE].value)
        }
      })
      return {
        tracking_id: tid,
        to: {
          name: parcelGroupsByTID[tid][0][MMCC_KEYS.TO_NAME].value,
          address: parcelGroupsByTID[tid][0][MMCC_KEYS.TO_ADDRESS].value,
          contact_number: parcelGroupsByTID[tid][0][MMCC_KEYS.TO_CONTACT_NUMBER].value
        },
        items
      }
    })
    const firstBox = Object.values(parcelGroupsByTID)[0]
    const dimensions: Dimension = {}
    if (firstBox[0][MMCC_KEYS.BOX_LENGTH].value) {
      dimensions.length = toNumber(firstBox[0][MMCC_KEYS.BOX_LENGTH].value)
    }
    if (firstBox[0][MMCC_KEYS.BOX_WIDTH].value) {
      dimensions.width = toNumber(firstBox[0][MMCC_KEYS.BOX_WIDTH].value)
    }
    if (firstBox[0][MMCC_KEYS.BOX_HEIGHT].value) {
      dimensions.height = toNumber(firstBox[0][MMCC_KEYS.BOX_HEIGHT].value)
    }
    return {
      batch_source_no: index + 1,
      ...(firstBox[0][MMCC_KEYS.BOX_WEIGHT].value && {
        weight: toNumber(firstBox[0][MMCC_KEYS.BOX_WEIGHT].value)
      }),
      ...(!isEmpty(dimensions) && {
        dimensions
      }),
      goods_description: firstBox[0][MMCC_KEYS.GOODS_DESCRIPTION].value,
      quantity: toNumber(firstBox[0][MMCC_KEYS.NO_OF_PARCELS].value),
      customs_currency: currency?.toUpperCase(),
      source_order_id: firstBox[0][MMCC_KEYS.BOX_ID].value,
      parcels: parcelList,
      to: {
        name: firstBox[0][MMCC_KEYS.RECIPIENT_NAME]?.value,
        address_line1: firstBox[0][MMCC_KEYS.RECIPIENT_ADDRESS]?.value,
        country_code: country,
        contact_number: firstBox[0][MMCC_KEYS.RECIPIENT_PHONE_NUMBER]?.value,
        ...(firstBox[0][MMCC_KEYS.RECIPIENT_CITY]?.value && {
          city: firstBox[0][MMCC_KEYS.RECIPIENT_CITY].value
        }),
        ...(firstBox[0][MMCC_KEYS.RECIPIENT_POSTCODE]?.value && {
          post_code: firstBox[0][MMCC_KEYS.RECIPIENT_POSTCODE].value
        })
      }
    }
  })
}

export const buildBoxesForB2BOrder = (
  orders: UploadedBoxOrder,
  currency: string,
  toCountryCode: string
): B2BBoxDetail[] => {
  return Object.values(orders).map(box => {
    const dimensions: Dimension = {}
    if (box[MMCC_KEYS.BOX_LENGTH]?.value) {
      dimensions.length = toNumber(box[MMCC_KEYS.BOX_LENGTH].value)
    }
    if (box[MMCC_KEYS.BOX_WIDTH]?.value) {
      dimensions.width = toNumber(box[MMCC_KEYS.BOX_WIDTH].value)
    }
    if (box[MMCC_KEYS.BOX_HEIGHT]?.value) {
      dimensions.height = toNumber(box[MMCC_KEYS.BOX_HEIGHT].value)
    }
    return {
      parcel_details: {
        customs_currency: currency.toUpperCase(),
        ...(!isEmpty(dimensions) && {
          dimensions
        }),
        value: toNumber(box[MMCC_KEYS.GOODS_VALUE]?.value),
        ...(box[MMCC_KEYS.BOX_WEIGHT]?.value && {
          weight: toNumber(box[MMCC_KEYS.BOX_WEIGHT].value)
        }),
        customs_description: box[MMCC_KEYS.GOODS_DESCRIPTION]?.value,
        quantity: toNumber(box[MMCC_KEYS.QUANTITY]?.value)
      },
      ...(box[MMCC_KEYS.BOX_ID]?.value && {
        source_order_id: box[MMCC_KEYS.BOX_ID].value
      }),
      to: {
        name: box[MMCC_KEYS.RECIPIENT_NAME]?.value,
        address_line1: box[MMCC_KEYS.RECIPIENT_ADDRESS]?.value,
        contact_number: box[MMCC_KEYS.RECIPIENT_PHONE_NUMBER]?.value,
        country_code: toCountryCode,
        ...(box[MMCC_KEYS.RECIPIENT_CITY]?.value && {
          city: box[MMCC_KEYS.RECIPIENT_CITY].value
        }),
        ...(box[MMCC_KEYS.RECIPIENT_POSTCODE]?.value && {
          post_code: box[MMCC_KEYS.RECIPIENT_POSTCODE].value
        })
      }
    }
  })
}

export const aggregateBoxFromUploadFile = (boxOrders, serviceType) => {
  if (!boxOrders) {
    return {
      totalBox: 0,
      totalGoodsValue: 0
    }
  }
  let totalGoodsValue = 0
  if (serviceType == SERVICE_TYPE.MMCCB2C) {
    boxOrders.forEach(box => {
      let totalInABox = 0
      box.parcels.forEach(parcel => {
        totalInABox += parcel.items.reduce((res, obj) => res + obj.quantity * obj.value, 0)
      })
      totalGoodsValue += totalInABox
    })
  } else {
    totalGoodsValue += boxOrders.reduce((res, obj) => res + obj.parcel_details.value, 0)
  }
  return {
    totalBox: boxOrders.length,
    totalGoodsValue: totalGoodsValue
  }
}

export const transferServiceDetailInfo = ({
  senderAddress,
  pickupType,
  pickupAddress,
  timeSlot,
  ocMethod,
  selectedService,
  commercialFiles,
  additionalDocumentFiles,
  serviceType
}) => {
  const { origin_country: originCountry, code } = selectedService

  const from = {
    name: senderAddress.name,
    address_line1: senderAddress.address1,
    country_code: originCountry,
    contact_number: senderAddress.contact,
    ...(senderAddress.city && {
      city: senderAddress.city
    }),
    ...(senderAddress?.email && {
      contact_email: senderAddress.email
    }),
    ...(senderAddress?.address2 && {
      address_line2: senderAddress.address2
    })
  }

  let pickup = null
  const slot = DELIVERY_TIME_PARAMS[+timeSlot?.timeWindow]
  if (pickupType === PICKUP_TYPES.SCHEDULED) {
    pickup = {
      pickup_date: timeSlot.pickupDate,
      pickup_instructions: timeSlot.comments,
      pickup_timeslot: {
        start_time: slot && slot.split('-')[0],
        end_time: slot && slot.split('-')[1]
      },
      pickup_address: {
        name: pickupAddress.name,
        address_line1: pickupAddress.address1,
        country_code: originCountry,
        contact_number: pickupAddress.contact,
        ...(pickupAddress.city && {
          city: pickupAddress.city
        }),
        city: pickupAddress.city,
        ...(pickupAddress?.email && {
          contact_email: pickupAddress.email
        }),
        ...(pickupAddress?.address2 && {
          address_line2: pickupAddress.address2
        })
      }
    }
  }
  // https://jira.ninjavan.net/browse/FPL-6142: Exclude postcode if origin country is not NV countries
  if (COUNTRIES[originCountry]) {
    from['post_code'] = senderAddress.postcode
    if (pickup) {
      pickup.pickup_address['post_code'] = pickupAddress.postcode
    }
  }
  let documents = null
  if (commercialFiles.length) {
    documents = {
      ...documents,
      commercial_invoices: commercialFiles.map(com => ({
        uri: com.data.file_uri,
        name: com.fileName
      }))
    }
  }
  if (additionalDocumentFiles.length) {
    const additionalFiles = additionalDocumentFiles.map(com => ({
      uri: com.data.file_uri,
      name: com.fileName
    }))
    documents = {
      ...documents,
      ...(serviceType === SERVICE_TYPE.B2B_BUNDLE && { additional_files: additionalFiles }),
      ...((serviceType === SERVICE_TYPE.MMCCB2B || serviceType === SERVICE_TYPE.MMCCB2C) && { delivery_orders: additionalFiles }),
    }
  }

  return {
    source: ocMethod,
    service_code: code,
    from,
    ...(pickup && {
      pickup
    }),
    ...(documents && {
      documents
    })
  }
}

export const buildB2CBoxRequests = ({
  boxOrders,
  senderAddress,
  pickupType,
  pickupAddress,
  timeSlot,
  ocMethod,
  selectedService,
  commercialFiles = [],
  additionalDocumentFiles = [],
  currency,
  serviceType
}) => {
  const { service_code, from, pickup, documents } = transferServiceDetailInfo({
    senderAddress,
    pickupType,
    pickupAddress,
    timeSlot,
    ocMethod,
    selectedService,
    commercialFiles,
    additionalDocumentFiles,
    serviceType
  })

  return {
    source: ocMethod,
    service_code,
    from,
    ...(pickup && {
      pickup
    }),
    boxes: boxOrders.map(box => ({ ...box, customs_currency: currency })),
    ...(documents && {
      documents
    })
  }
}

export const buildBoxesForB2BOrders = (orders, recipientAddress, destinationCountry) => {
  return orders.map(order => {
    const dimensions: Dimension = {}
    if (order.length) {
      dimensions.length = toNumber(order.length)
    }
    if (order.width) {
      dimensions.width = toNumber(order.width)
    }
    if (order.height) {
      dimensions.height = toNumber(order.height)
    }
    return {
      parcel_details: {
        ...(!isEmpty(dimensions) && {
          dimensions
        }),
        value: toNumber(order.goodsValue),
        ...(order.weight && {
          weight: toNumber(order.weight)
        }),
        customs_description: trim(order.goodsDescription),
        quantity: toNumber(order.quantity)
      },
      ...(order.boxId && {
        source_order_id: trim(order.boxId)
      }),
      ...(recipientAddress && {
        to: {
          name: trim(recipientAddress.name),
          address_line1: trim(recipientAddress.address1),
          ...(recipientAddress.city && {
            city: trim(recipientAddress.city)
          }),
          ...(recipientAddress.postcode && {
            post_code: trim(recipientAddress.postcode)
          }),
          country_code: destinationCountry,
          contact_number: trim(recipientAddress.contact)
        }
      })
    }
  })
}

export const getCreationBoxFormRules = intl => {
  return {
    boxId: [
      {
        required: true,
        message: intl.formatMessage({ id: 'international_shipper_box_id_is_required' })
      },
      {
        min: 1,
        message: intl.formatMessage({ id: 'international_invalid_shipper_box_id' })
      },
      {
        max: 255,
        message: intl.formatMessage({ id: 'international_invalid_shipper_box_id' })
      }
    ],
    goodsDescription: [
      {
        required: true,
        message: intl.formatMessage({ id: 'international_goods_description_is_required' })
      },
      {
        min: 1,
        message: intl.formatMessage({ id: 'international_goods_description_is_invalid' })
      },
      {
        max: 255,
        message: intl.formatMessage({ id: 'international_goods_description_is_invalid' })
      }
    ],
    quantity: [
      {
        required: true,
        message: intl.formatMessage({ id: 'international_no_of_parcels_is_required' })
      },
      {
        pattern: INTEGER_NUMBER_AND_GREATER_THAN_0,
        message: intl.formatMessage({ id: 'international_no_of_parcels_is_invalid' })
      }
    ],
    goodsValue: [
      {
        required: true,
        message: intl.formatMessage({ id: 'international_goods_value_is_required' })
      },
      {
        pattern: FLOAT_NUMBER_AND_GREATER_THAN_0,
        message: intl.formatMessage({ id: 'international_goods_value_is_invalid' })
      }
    ],
    length: [
      {
        pattern: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
        message: intl.formatMessage({ id: 'international_invalid_box_length' })
      }
    ],
    width: [
      {
        pattern: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
        message: intl.formatMessage({ id: 'international_invalid_box_width' })
      }
    ],
    height: [
      {
        pattern: FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000,
        message: intl.formatMessage({ id: 'international_invalid_box_height' })
      }
    ],
    weight: [
      {
        pattern: FLOAT_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000,
        message: intl.formatMessage({ id: 'international_box_weight_is_invalid' })
      }
    ]
  }
}

export const buildB2BBoxRequests = ({
  boxOrders,
  senderAddress,
  pickupType,
  pickupAddress,
  timeSlot,
  ocMethod,
  selectedService,
  commercialFiles = [],
  additionalDocumentFiles = [],
  currency,
  serviceType
}) => {
  const { service_code, from, pickup, documents } = transferServiceDetailInfo({
    senderAddress,
    pickupType,
    pickupAddress,
    timeSlot,
    ocMethod,
    selectedService,
    commercialFiles,
    additionalDocumentFiles,
    serviceType
  })

  const requests = boxOrders.map(d => {
    return {
      source: ocMethod,
      service_code,
      from,
      ...(pickup && {
        pickup
      }),
      parcel_details: {
        ...d.parcel_details,
        customs_currency: toUpper(currency)
      },
      to: d.to,
      ...(d.source_order_id && {
        source_order_id: d.source_order_id
      })
    }
  })
  return {
    order_requests: requests,
    ...(documents && { documents })
  }
}

export const buildB2BBundleRequest = ({
  bundles,
  senderAddress,
  pickupType,
  pickupAddress,
  timeSlot,
  ocMethod,
  selectedService,
  commercialFiles = [],
  additionalDocumentFiles = [],
  currency,
  serviceType
}) => {
  const { service_code, from, pickup, documents } = transferServiceDetailInfo({
    senderAddress,
    pickupType,
    pickupAddress,
    timeSlot,
    ocMethod,
    selectedService,
    commercialFiles,
    additionalDocumentFiles,
    serviceType
  })
  const requests = bundles.map((bundle: B2BBundleOrderFormGroup) => {
    const dimensions = {
      ...(bundle.length && { length: +bundle.length }),
      ...(bundle.width && { width: +bundle.width }),
      ...(bundle.height && { height: +bundle.height }),
    }
    const documentsRequired = []
    if (bundle.rdoRequired) {
      documentsRequired.push(BUNDLE_REQUIRED_OPTIONS.RDO)
    }
    if (bundle.grnRequired) {
      documentsRequired.push(BUNDLE_REQUIRED_OPTIONS.GRN)
    }
    let requestedCartonTIDs = []
    if (bundle.requestedCartonTrackingNumbers) {
      requestedCartonTIDs = bundle.requestedCartonTrackingNumbers?.split(',')
      .filter(tid => !isEmpty(tid))
      .map(cartonTid => trim(cartonTid?.toUpperCase()))
    }
    return {
      source: ocMethod,
      service_code,
      from,
      ...(bundle.requestedTrackingID && { requested_tracking_id: bundle.requestedTrackingID?.toUpperCase() }),
      ...(bundle.shipperOrderNumber && { source_order_id: bundle.shipperOrderNumber?.toUpperCase() }),
      ...(pickup && {
        pickup
      }),
      details: {
        origin_country: selectedService.origin_country,
        value: +bundle.totalCartonValue,
        quantity: +bundle.numberOfCartons,
        description: bundle.description,
        customs_currency: toUpper(currency),
        ...(!isEmpty(documentsRequired) && { documents_required: documentsRequired }),
        ...(!isEmpty(requestedCartonTIDs) && { requested_piece_tracking_ids: requestedCartonTIDs }),
        ...(!isEmpty(dimensions) && { dimensions: { ...dimensions, unit: DEFAULT_DIMENSION_UNIT } }),
        ...(bundle.weight && { weight: +bundle.weight }),
        ...(bundle.hsCode && { hs_code: bundle.hsCode }),
      },
      to: {
        name: bundle.name,
        address_line1: bundle.addressLine1,
        country_code: selectedService.destination_country,
        contact_number: bundle.contact,
        ...(bundle.email && { contact_email: bundle.email }),
        ...(bundle.stateProvince && { state_province: bundle.stateProvince }),
        ...(bundle.city && { city: bundle.city }),
        ...(bundle.addressLine2 && { address_line2: bundle.addressLine2 }),
        ...(bundle.postCode && { post_code: bundle.postCode })
      }
    }
  })
  return {
    order_requests: requests,
    ...(documents && { documents })
  }
}

export const checkValidSupportedFile = (file, fileIndex) => {
  const errors = []
  if (fileIndex > 1) {
    errors.push('max_file_upload')
  }
  const fileSizeInByMB = file.size / 1024 / 1024
  if (fileSizeInByMB > 50) {
    errors.push('upload_exceed_file_size')
  }
  const isPdf = endsWith(file.name, '.pdf')
  const isCsv = endsWith(file.name, '.csv')
  const isXls = endsWith(file.name, '.xls')
  const isXlsx = endsWith(file.name, '.xlsx')
  const isJpg = endsWith(file.name, '.jpg')
  const isPng = endsWith(file.name, '.png')
  if (!isPdf && !isCsv && !isXls && !isXlsx && !isJpg && !isPng) {
    errors.push('unsupported_file_upload')
  }
  return errors
}

export const calculateFileInfo = data => {
  const totalSizeMB = round(data.reduce((sum, file) => sum + file.fileSize, 0) / 1024 / 1024, 2)
  const tatalSizeKB = round(data.reduce((sum, file) => sum + file.fileSize, 0) / 1024, 2)
  return {
    size: totalSizeMB > 0.01 ? totalSizeMB : tatalSizeKB,
    totalFiles: data.length,
    unit: totalSizeMB > 0.01 ? 'MB' : 'KB'
  }
}

export const getMMCCKeyTabs = isItemised => ({
  VALID_ORDER: {
    LABEL: isItemised ? 'international_valid_items' : 'international_valid_boxes',
    KEY: 'VALID_ORDERS'
  },
  INVALID_ORDER: {
    LABEL: isItemised ? 'international_invalid_items' : 'international_invalid_boxex',
    KEY: 'INVALID_ORDER'
  },
  ALL_ORDERS: {
    LABEL: isItemised ? 'international_total_items' : 'international_total_boxes',
    KEY: 'ALL_ORDERS'
  }
})

export const sortListWithSelectedFirst = (list, selected) => {
  if (!selected) return list
  return list.sort((a, b) => {
    if (a.id === selected.id) return -1
    if (b.id === selected.id) return 1
    return 0
  })
}
export const getCreationRecipientFormRules = (country: string, intl?: any) => {
  const postcodeDigits = postcodeDigitsByCountry(country)
  const countryLowerCase = toLower(country)
  return {
    name: [
      {
        required: true,
        message: intl?.formatMessage({ id: 'international_recipient_name_is_required' })
      },
      {
        min: 1,
        message: intl?.formatMessage({ id: 'international_name_less_than_255_characters' })
      },
      {
        max: 255,
        message: intl?.formatMessage({ id: 'international_name_less_than_255_characters' })
      }
    ],
    phone: [
      {
        required: true,
        message: intl?.formatMessage({ id: 'phone_number_required' })
      },
      {
        pattern: PHONE_REGEX,
        message: intl?.formatMessage({ id: 'international_conact_number_must_be_valid_format' })
      }
    ],
    address: [
      {
        required: true,
        message: intl?.formatMessage({ id: 'international_recipient_address_is_required' })
      },
      {
        min: 1,
        message: intl?.formatMessage({ id: 'international_address_less_than_255_characters' })
      },
      {
        max: 255,
        message: intl?.formatMessage({ id: 'international_address_less_than_255_characters' })
      }
    ],
    city: [
      {
        required: isRequiredCity(countryLowerCase), // city is required all countries except SG and TH
        message: intl?.formatMessage({ id: 'types.zone.city.required' })
      },
      {
        min: 1,
        message: intl?.formatMessage({ id: 'international_city_less_than_255_characters' })
      },
      {
        max: 255,
        message: intl?.formatMessage({ id: 'international_city_less_than_255_characters' })
      }
    ],
    postcode: [
      {
        required: isRequiredPostCode(countryLowerCase), // city is required for Singapore, Malaysia, Thailand
        message: intl?.formatMessage({ id: 'postcode_required' })
      },
      {
        pattern: VALIDATORS.POSTCODE_VALIDATOR[country],
        message: intl?.formatMessage({ id: 'postcode_restriction' }, { x: postcodeDigits })
      }
      /*
       * post code rules:
       *  Singapore: 6 digits
       *  Malaysia: 5 digits
       *  Thailand: 5 digits
       *  Philippines: 4 digits
       *  Indonesia: 5 digits
       *  Vietnam: 6 digits
       */
    ]
  }
}

export const validateRecipientInGroup = (address, country) => {
  const lowercaseCountry = lowerCase(country)
  if (!address) {
    return false
  }

  const { name, contact, address1, city, postcode } = address
  if (!validateName(name)) {
    return false
  }
  if (!validateContact(contact)) {
    return false
  }
  if (!validateAddress1(address1)) {
    return false
  }
  if (!validateCity(city, lowercaseCountry)) {
    return false
  }
  if (!validatePostcode(postcode, lowercaseCountry)) {
    return false
  }

  return true
}

export const isValidBundle = (bundle: B2BBundleOrderFormGroup, country: string, inactiveBundles: B2BBundleOrderFormGroup[]) => {
  const { name, contact, addressLine1, city,
    postCode, addressLine2, email, numberOfCartons,
    totalCartonValue, shipperOrderNumber, weight,
    length, width, height, description, hsCode,
    requestedTrackingID, requestedCartonTrackingNumbers
  } = bundle
  if (!validateName(name)) {
    return false
  }
  if (!validateContact(contact)) {
    return false
  }
  if (!validateAddress1(addressLine1)) {
    return false
  }
  if (!validateCity(city, lowerCase(country))) {
    return false
  }
  if (!validatePostcode(postCode, lowerCase(country))) {
    return false
  }
  if (!validateAddress2(addressLine2)) {
    return false
  }
  if (!validateEmail(email)) {
    return false
  }
  if (!validateNumberOfCartons(numberOfCartons)) {
    return false
  }
  if (!validateTotalCartonValues(totalCartonValue)) {
    return false
  }
  if (!validateShipperOrderNumber(shipperOrderNumber)) {
    return false
  }
  if (!validateOptionalAndFollowingPatternNumber(weight, FLOAT_NUMBER_AND_GREATER_THAN_0_AND_LESS_THAN_1000)) {
    return false
  }
  if (!validateOptionalAndFollowingPatternNumber(length, FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000)) {
    return false
  }
  if (!validateOptionalAndFollowingPatternNumber(width, FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000)) {
    return false
  }
  if (!validateOptionalAndFollowingPatternNumber(height, FLOAT_NUMBER_AND_GREATER_THAN_1_AND_LESS_THAN_1000)) {
    return false
  }
  if (!validateRequiredAndLengthOfValue(description, NUMBER_1000)) {
    return false
  }
  if (!validateOptionalAndLengthOfValue(hsCode, NUMBER_1000)) {
    return false
  }
  if (requestedTrackingIdB2BBundleValidator(requestedTrackingID, inactiveBundles)) {
    return false
  }
  if (requestedCartonsTrackingIDsValidator(requestedCartonTrackingNumbers, numberOfCartons, inactiveBundles)) {
    return false
  }
  return true
}
