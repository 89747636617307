import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StyledCardHeaderText, StyledPreview, StyledSummaryCard } from './styles'
import { Row, T, Icon } from '@nv/react-commons/src/Components'
import { Colors } from 'themes'
import { faEdit } from '@fa-pro-light/faEdit'
import { Vspace } from 'components/Vspace'
import { CenterText } from 'components/CenterText'
import { Text } from 'components/Text'
import { ShortText } from 'containers/FPLMMCCOrderCreation/styles'

const StyledRow = styled(Row)`
  flex: 1;
  width: 50%;
  margin-right: 16px;
`

const Preview = ({ id, asCard, order, selected, onSelect }) => {
  const { completed, rowNum, name } = order
  const pos = rowNum || id + 1

  return (
    <StyledPreview
      asCard={asCard}
      onClick={() => {
        !selected && onSelect(id)
      }}
      data-analyticsid='4plOCEditManualOrder'
      data-testid='4plOrderSummary'
      type='flex'
      justify='space-between'
      align='middle'
      selected={selected}
    >
      <StyledRow>
        <Text type='bold' size={12} color={Colors.pinkishGrey} inline>
          <T id='order_x' values={{ x: pos }} />
        </Text>
        <ShortText type='bold' size={14}>
          {name}
        </ShortText>
      </StyledRow>
      {(selected || completed) && (
        <Text color={Colors.apricot} style={{ marginRight: 8 }}>
          <T id={completed ? 'completed' : 'editing'} />
        </Text>
      )}
      {!selected && <Icon color={Colors.warmGrey} type={asCard ? 'medium' : 'small'} icon={faEdit} gap={1} />}
    </StyledPreview>
  )
}

Preview.propTypes = {
  asCard: PropTypes.bool,
  id: PropTypes.number,
  onSelect: PropTypes.func,
  order: PropTypes.object,
  selected: PropTypes.bool
}

const OrderSummary = ({ orders, selectedId, onSelectOrder }) => {
  const orderSummaryHeader = () => {
    return (
      <Row type='flex' align='middle' justify='space-between'>
        <StyledCardHeaderText>
          <T id='total_orders' />
        </StyledCardHeaderText>
        <StyledCardHeaderText>{orders.length}</StyledCardHeaderText>
      </Row>
    )
  }

  return (
    <StyledSummaryCard title={orderSummaryHeader()}>
      {orders.length ? (
        orders.map((o, i) => <Preview key={i} id={i} order={o} onSelect={onSelectOrder} selected={i === selectedId} />)
      ) : (
        <>
          <Vspace height={24} />
          <CenterText color={Colors.pinkishGrey}>
            <T id='key_order_to_begin' />
          </CenterText>
        </>
      )}
    </StyledSummaryCard>
  )
}

OrderSummary.propTypes = {
  orders: PropTypes.array,
  selectedId: PropTypes.number,
  onSelectOrder: PropTypes.func,
  onClickContinue: PropTypes.func
}

OrderSummary.defaultProps = {
  orders: [],
  selectedId: 0
}

export { OrderSummary, Preview }
