import { RDOorGRN } from 'components/OCForm/Field'
import {
  City,
  PostCode,
  StateProvince
} from 'containers/FPLOrderRequestList/Fields'
import {
  AddressLine1,
  AddressLine2,
  CartonDescription,
  CartonsNumber,
  CustomerContact,
  CustomerEmail,
  CustomerName,
  Height,
  HsCode,
  Length,
  ShipperOrderNumber,
  TotalCartonValue,
  Weight,
  Width,
  RequestedCartonTIDs,
  RequestedTrackingID
} from './B2BBundleFields'

export const FORM_FIELDS_BY_SECTION = {
  recipient: [
    [CustomerName, 8], // [Component, the width of column]. Example the width of column: <Col lg={8}>{children}</Col>
    [CustomerContact, 8],
    [CustomerEmail, 8],
    [CartonsNumber, 8],
    [TotalCartonValue, 8],
    [ShipperOrderNumber, 8]
  ],
  address: [
    [PostCode, 8],
    [StateProvince, 8],
    [City, 8],
    [AddressLine1, 12],
    [AddressLine2, 12],
  ],
  carton: [
    [Weight, 6],
    [Length, 6],
    [Width, 6],
    [Height, 6],
    [RDOorGRN, 24],
    [RequestedTrackingID, 8],
    [null, 12],
    [RequestedCartonTIDs, 8],
    [CartonDescription, 8],
    [HsCode, 8]
  ]
}
