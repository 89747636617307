/**
 *
 * 4PL PickupType
 *
 */

import React, { useState, useEffect } from 'react'
import { useIntl } from 'hooks/common'
import { OCSubStep } from 'components/OCSubStep'
import { PICKUP_TYPES } from 'containers/PickupType/constants'
import { T } from '@nv/react-commons/src/Components'
import { Preview } from 'components/Preview'
import { faShippingTimed } from '@fa-pro-light/faShippingTimed'
import { PickupTypeProps } from './types'
import { SpacedBetweenFlex, StyledRadio, StyledRadioGroup } from './styles'
import { PICKUP_METADATA } from './constants'

export const PickupType = ({ onCancel, onSave, onEdit, status, pickupType, allowPickupChange }: PickupTypeProps) => {
  const [pickup, setPickup] = useState(pickupType)
  const intl = useIntl()

  useEffect(() => {
    setPickup(pickupType)
  }, [pickupType])

  const handleChangePickupType = e => {
    const value = e.target.value
    setPickup(value)
  }

  const handleCancel = () => {
    setPickup(pickupType)
    onCancel()
  }

  const renderOption = (key) => {
    const { title, description } = PICKUP_METADATA[key]
    return (
      <StyledRadio key={key} value={key} disabled={!allowPickupChange}>
        <SpacedBetweenFlex>
          <Preview title={<T id={title} />} description={<T id={description} />} />
        </SpacedBetweenFlex>
      </StyledRadio>
    )
  }

  const renderActive = () => {
    return (
      <StyledRadioGroup onChange={handleChangePickupType} value={pickup}>
        {renderOption(PICKUP_TYPES.SCHEDULED)}
        {renderOption(PICKUP_TYPES.NO)}
      </StyledRadioGroup>
    )
  }

  const renderPreview = () => {
    const { title } = PICKUP_METADATA[pickup]
    return <Preview title={<T id={title} />} />
  }

  return (
    <OCSubStep
      status={status}
      title={intl.formatMessage({ id: 'pickup' })}
      subtitle='choose_pickup_type'
      icon={faShippingTimed}
      renderActive={renderActive}
      renderPreview={renderPreview}
      onSave={() => {
        onSave(pickup)
      }}
      onEdit={onEdit}
      onCancel={handleCancel}
    />
  )
}
