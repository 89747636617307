import React, { useContext, useMemo, useState } from 'react'
import Button from 'components/Button'
import { Col, Form, Row, T } from '@nv/react-commons/src/Components'
import { faArrowLeft } from '@fa-pro-light/faArrowLeft'
import { faBoxes } from '@fa-pro-light/faBoxes'
import {
  CREATION_METHODS,
  INPUT_METHODS,
  ORDER_CREATE_SOURCE,
  SERVICE_TYPE_LABEL_IN_MIXPANEL
} from 'containers/FPLOrderCreate/constants'
import { ButtonFullWidth, EndedDivBlock, HorizontalSeperateLine, InputZone, StyledMinCard } from './styles'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { RecipientInSummary } from './RecipientInSummary'
import { KEYBOARD_INPUT_STATUS, MMCC_OC_STEP, DEFAULT_MMCC_B2B_FORM_FIELD } from './constants';
import { validateRecipientInGroup } from './utils'
import { PreviewStepInfo } from './PreviewStepInfo'
import { TRACK_ADDED_INTL_ORDER_DETAIL } from 'containers/FPLMixpanel/constants'
import { mpTrackAddedIntlOrderDetail } from 'containers/FPLMixpanel/helpers'
import { RecipientForm } from './RecipientForm'
import { BoxSection } from './BoxSection'
import { compose } from 'redux'
import { max, toNumber } from 'lodash'
import { Text } from 'components/Text'

export const MMCCKeyboardForm = ({ form, onMethodChange }) => {
  const [isShowMissingBox, setIsShowMissingBox] = useState(false)
  const [isResetBoxForm, setIsResetBoxForm] = useState(false)

  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const { keyboardInputStatus, selectedService, mmccB2BFormGroup, activeGroupId, customsCurrency } = ocState
  const { destination_country: destinationCountry } = selectedService

  const totalValuesInPreviewOrder = useMemo(() => {
    let totalBox = 0
    let totalGoodsValue = 0
    Object.values(mmccB2BFormGroup).map(group => {
      const isFilledBoxes = !!group.boxes.length
      const isValidRecipient = validateRecipientInGroup(group.address, destinationCountry)
      if (isFilledBoxes && isValidRecipient) {
        totalBox += group.boxes.length
      }
      const goodValuesInGroup = group.boxes.reduce((total, order) => total + toNumber(order.goodsValue), 0)
      totalGoodsValue += goodValuesInGroup
    })
    return {
      totalBox,
      totalGoodsValue
    }
  }, [mmccB2BFormGroup])

  const totalValidRecipient = useMemo(() => {
    return Object.values(mmccB2BFormGroup).filter(group => {
      const isFilledBoxes = !!group.boxes.length
      const isValidRecipient = validateRecipientInGroup(group.address, destinationCountry)
      return isFilledBoxes && isValidRecipient
    }).length
  }, [mmccB2BFormGroup])

  const initRecipientFormData = useMemo(() => {
    return mmccB2BFormGroup[activeGroupId].address
  }, [mmccB2BFormGroup, activeGroupId])

  const handleChangeCreationMethod = method => {
    updateOCState({ ocMethod: method })
  }

  const toggleOffResetForm = () => setIsResetBoxForm(false)

  const resetForm = () => {
    form.resetFields()
    setIsResetBoxForm(true)
  }

  const saveOrders = () => {
    updateOCState({
      keyboardInputStatus: KEYBOARD_INPUT_STATUS.IN_PREVIEW,
      currentStep: MMCC_OC_STEP.COMMERCIAL_INVOICE,
      visibleAddBoxForm: false
    })

    const validBoxes = Object.values(mmccB2BFormGroup).filter(group => group.boxes.length)
    const { PROPS } = TRACK_ADDED_INTL_ORDER_DETAIL
    mpTrackAddedIntlOrderDetail({
      [PROPS.METHOD]: CREATION_METHODS[ORDER_CREATE_SOURCE.KEYBOARD],
      [PROPS.NUMBER_OF_VALID_ORDER]: validBoxes.length,
      [PROPS.NUMBER_OF_INVALID_ORDER]: 0,
      [PROPS.TOTAL_ORDERS]: validBoxes.length,
      [PROPS.ORDER_TYPE]: SERVICE_TYPE_LABEL_IN_MIXPANEL[selectedService.type]
    })
  }

  const handleEditStep = () => {
    updateOCState({ keyboardInputStatus: KEYBOARD_INPUT_STATUS.IN_SHOWING, visibleAddBoxForm: false })
  }

  const addNewRecipient = () => {
    form.validateFields(err => {
      if (!err) {
        const newmmccB2BFormGroup = { ...mmccB2BFormGroup }

        if (newmmccB2BFormGroup[activeGroupId].boxes.length) {
          const nextActiveGroupId = max(Object.values(newmmccB2BFormGroup).map(g => g.id)) + 1
          updateOCState({
            activeGroupId: nextActiveGroupId,
            visibleAddBoxForm: true,
            mmccB2BFormGroup: {
              ...mmccB2BFormGroup,
              [nextActiveGroupId]: {
                ...DEFAULT_MMCC_B2B_FORM_FIELD,
                id: nextActiveGroupId,
              }
            }
          })
          setIsShowMissingBox(false)
          resetForm()
        } else {
          setIsShowMissingBox(true)
        }
      }
    })
  }

  const handleShowMissingBox = visible => {
    setIsShowMissingBox(visible)
  }

  const selectFormGroup = groupId => {
    resetForm()
    updateOCState({
      activeGroupId: groupId,
      visibleAddBoxForm: !mmccB2BFormGroup[groupId].boxes.length
    })
  }

  const deleteFormGroup = groupId => {
    resetForm()
    const findGroupIndex = groupId => Object.values(mmccB2BFormGroup).findIndex(g => g.id === groupId)
    const updateState = (newmmccB2BFormGroup, activeGroupId) => {
      updateOCState({
        activeGroupId,
        mmccB2BFormGroup: newmmccB2BFormGroup,
        visibleAddBoxForm: false
      })
    }

    const deletedGroupIndex = findGroupIndex(groupId)
    const newmmccB2BFormGroup = { ...mmccB2BFormGroup }
    delete newmmccB2BFormGroup[groupId]

    const previousGroup = Object.values(mmccB2BFormGroup)[deletedGroupIndex - 1]
    const nextGroup = Object.values(mmccB2BFormGroup)[deletedGroupIndex + 1]

    if (previousGroup) {
      return updateState(newmmccB2BFormGroup, previousGroup.id)
    }

    if (nextGroup) {
      return updateState(newmmccB2BFormGroup, nextGroup.id)
    }

    newmmccB2BFormGroup[activeGroupId] = {
      id: activeGroupId,
      address: null,
      boxes: [],
      cursorBox: null
    }

    if (!previousGroup && !nextGroup) {
      updateOCState({
        mmccB2BFormGroup: newmmccB2BFormGroup,
        visibleAddBoxForm: true
      })
    } else {
      updateOCState({
        mmccB2BFormGroup: newmmccB2BFormGroup,
        visibleAddBoxForm: false
      })
    }
  }

  return (
    <>
      {keyboardInputStatus === KEYBOARD_INPUT_STATUS.IN_SHOWING && (
        <>
          <Row gutter={14}>
            <Col span={18}>
              <StyledMinCard hoverable={false}>
                <Button size='small' icon={faArrowLeft} onClick={() => handleChangeCreationMethod(undefined)}>
                  <T id='choose_input' allCaps />
                </Button>
                <HorizontalSeperateLine className='mt-2 mb-2' />

                <InputZone>
                  <Text id='recipient_information' type='bold' />
                  <RecipientForm form={form} country={destinationCountry} initFormData={initRecipientFormData} />
                  <BoxSection
                    isShowMissingBox={isShowMissingBox}
                    onShowMissingBox={handleShowMissingBox}
                    activeGroupId={activeGroupId}
                    mmccB2BFormGroup={mmccB2BFormGroup}
                    updateOCState={updateOCState}
                    isResetBoxForm={isResetBoxForm}
                    toggleOffResetForm={toggleOffResetForm}
                  />
                </InputZone>
                <HorizontalSeperateLine className='mt-2 mb-2' />
                <EndedDivBlock>
                  <Button onClick={addNewRecipient} type='primary' size='medium'>
                    <T id='add_new_recipient' allCaps />
                  </Button>
                </EndedDivBlock>
              </StyledMinCard>
            </Col>
            <Col span={6}>
              <RecipientInSummary
                country={destinationCountry}
                totalValidRecipient={totalValidRecipient}
                orderFormGroup={mmccB2BFormGroup}
                activeGroupId={activeGroupId}
                onSelectFormGroup={selectFormGroup}
                onDeleteFormGroup={deleteFormGroup}
              />
              <ButtonFullWidth type='primary' size='medium' disabled={!totalValidRecipient} onClick={saveOrders}>
                <T id='save_and_continue' allCaps />
              </ButtonFullWidth>
            </Col>
          </Row>
        </>
      )}

      {keyboardInputStatus === KEYBOARD_INPUT_STATUS.IN_PREVIEW && (
        <PreviewStepInfo
          quantity={totalValuesInPreviewOrder.totalBox}
          unit={customsCurrency}
          creationMethod={INPUT_METHODS.KEYBOARD}
          totalGoodsValue={totalValuesInPreviewOrder.totalGoodsValue}
          totalRecipient={totalValidRecipient}
          titleIntlKey={'total_recipient'}
          icon={faBoxes}
          onEditStep={handleEditStep}
        />
      )}
    </>
  )
}

export const MMCCKeyboard = compose(
  Form.create({
    onValuesChange: (props, values) => {
      const { orderFormGroup, activeGroupId, updateOCState } = props
      const updatedGroup = { ...orderFormGroup }
      updatedGroup[activeGroupId].address = {
        ...updatedGroup[activeGroupId].address,
        ...values
      }
      updateOCState({
        mmccB2BFormGroup: updatedGroup
      })
    }
  })
)(React.memo(MMCCKeyboardForm))
