import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'hooks/common'
import { ExcelUtils, FileUtils } from '@nv/react-commons/src/Utils'
import { Vspace } from 'components/Vspace'
import { CircleIcon } from 'components/CircleIcon'
import { faCloudUpload } from '@fa-pro-light/faCloudUpload'
import { Menu, T } from '@nv/react-commons/src/Components'
import { Text } from 'components/Text'
import { DropZone } from 'containers/FPLAllOrders/DropZone'
import { InlineErrorMessage } from 'containers/FPLAllOrders/InlineErrorMessage'
import { ItemButton, StyledCenterText, StyledTextInline } from '../FPLOrderCreate/styles'
import { friendlyErrMessage } from '../FPLOrderCreate/dataUtils'
import { Dropdown } from 'antd/lib'
import { generateTemplate, verifyFileExtensionAndTemplate } from './utils'
import { mpTrackUploadFile } from 'containers/FPLMixpanel/helpers'
import { useSelector } from 'react-redux'
import { selectUser } from 'containers/Base/selectors'
import { SERVICE_TYPE, SERVICE_TYPE_LABEL_IN_MIXPANEL } from '../FPLOrderCreate/constants'

const initialState = {
  fileName: '',
  errorMessages: [],
  isLoadingFile: false
}

const BulkUploadZone = ({ serviceType, onSave }) => {
  const [state, setState] = useState(initialState)
  const intl = useIntl()

  const { fileName, errorMessages, isLoadingFile } = state
  const locale = useSelector(selectUser())?.locale || 'en'

  const templateGenerator = useMemo(() => generateTemplate(serviceType, locale, intl), [serviceType, locale, intl])

  const handleUploadFile = async file => {
    setState(prevState => ({ ...prevState, isLoadingFile: true }))
    if (!file) {
      const errMsg = intl.formatMessage({ id: 'international_advanced_search_wrong_format' })
      setState({
        isLoadingFile: false,
        fileName: undefined,
        errorMessages: [errMsg]
      })
      mpTrackUploadFile({
        result: 'Fail - international_advanced_search_wrong_format',
        numberOfValidOrders: 0,
        numberOfInvalidOrders: 0,
        totalOrders: 0,
        errorMessage: errMsg,
        orderType: SERVICE_TYPE_LABEL_IN_MIXPANEL[serviceType]
      })
      return
    }

    const { fileData, errorMsg, fileName: name } = await verifyFileExtensionAndTemplate(file, serviceType, locale)
    if (fileData.data.length === 0) {
      const errMsg = intl.formatMessage({ id: 'international_advanced_search_wrong_format' })
      setState({
        isLoadingFile: false,
        fileName: undefined,
        errorMessages: [errMsg]
      })
      mpTrackUploadFile({
        result: 'Fail - international_advanced_search_wrong_format',
        numberOfValidOrders: 0,
        numberOfInvalidOrders: 0,
        totalOrders: 0,
        errorMessage: errMsg,
        orderType: SERVICE_TYPE_LABEL_IN_MIXPANEL[serviceType]
      })
      return
    }

    setState({
      fileName: name,
      isLoadingFile: false,
      errorMessages: friendlyErrMessage(intl, errorMsg)
    })

    if (!errorMsg.length) {
      onSave({ filename: name, data: fileData.data })
    } else {
      mpTrackUploadFile({
        result: `Fail - ${errorMsg.map(mes => mes.id).join('\n')}`,
        numberOfValidOrders: 0,
        numberOfInvalidOrders: 0,
        totalOrders: 0,
        errorMessage: errorMsg.map(mes => intl.formatMessage({ id: mes.id })).join('\n'),
        orderType: SERVICE_TYPE_LABEL_IN_MIXPANEL[serviceType]
      })
    }
  }

  const handleDownloadExcel = () => {
    const downloadingFileName =
      serviceType === SERVICE_TYPE.MMCCB2C ? 'international_b2c_mccc_sample.xlsx' : 'international_b2b_mccc_sample.xlsx'
    const { dataFields, headerFields } = templateGenerator
    ExcelUtils.downloadFileWithHeaders(dataFields, [headerFields], downloadingFileName)
  }

  const handleDownloadCsv = () => {
    const downloadingFileName =
      serviceType === SERVICE_TYPE.MMCCB2C ? 'international_b2c_mccc_sample.csv' : 'international_b2b_mccc_sample.csv'
    const { dataFields, headerFields } = templateGenerator
    FileUtils.download(
      FileUtils.buildCSVURI({
        fields: headerFields,
        data: dataFields
      }),
      { filename: downloadingFileName }
    )
  }

  const renderMenu = () => {
    return (
      <Menu>
        <Menu.Item key='xb-bulk-oc-csv'>
          <ItemButton data-test-id='download-international-sample' onClick={handleDownloadCsv}>
            <T id='international_csv_templates' />
          </ItemButton>
        </Menu.Item>
        <Menu.Item key='xb-bulk-oc-xlx'>
          <ItemButton onClick={handleDownloadExcel}>
            <T id='international_excel_templates' />
          </ItemButton>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <>
      <DropZone
        data-analyticsid='browseFilesForBulkOC'
        height={200}
        onDrop={handleUploadFile}
        hint={
          <>
            <CircleIcon icon={faCloudUpload} size='big' type='success' gap={1} />
            <Vspace height={16} />
            <StyledCenterText size={16} id='upload_csv_xls' />
          </>
        }
      />
      <InlineErrorMessage isLoading={isLoadingFile} errorMessages={errorMessages} fileName={fileName} />
      <>
        <Vspace height={16} />
        <Text>
          <T id='international_noted' />:
        </Text>
        <StyledTextInline style={{ display: 'flex' }}>
          <Text>
            <T id='international_bulk_upload_note_description' />
            ,&nbsp;
          </Text>
          <Dropdown
            style={{ height: '40px', width: '200px' }}
            overlay={renderMenu()}
            placement='bottomLeft'
            trigger={['click']}
          >
            <a data-analyticsid='downloadCSVMappingTemplate'>
              <T id='international_template_download' />
            </a>
          </Dropdown>
        </StyledTextInline>
      </>
    </>
  )
}

BulkUploadZone.propTypes = {
  onSave: PropTypes.func
}

export { BulkUploadZone }
