import { MMCC_SERVICE_TYPE } from "./constants"
import { overwrite } from 'country-list'

overwrite([
  {
    code: 'TW',
    name: 'Taiwan'
  },
  {
    code: 'US',
    name: 'United States'
  }
])

const calculateResponseTime = () => {
  let startTime

  const startCounting = () => {
    startTime = new Date()
  }

  const getTime = () => {
    return new Date() - startTime
  }

  const reset = () => {
    startTime = undefined
  }

  return { startCounting, getTime, reset }
}

const getE2EServices = services => {
  if (!services?.length) return []
  return services.filter(service => !MMCC_SERVICE_TYPE.includes(service.type))
}

export { calculateResponseTime, getE2EServices }
