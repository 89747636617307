import React, { useMemo } from 'react'
import { StyledForm } from 'containers/FPLOrderCreate/styles'
import { T, Col } from '@nv/react-commons/src/Components'
import { PaddingRow, SmallInput } from './styles'
import { StyledFormItem } from 'containers/FPLOrderRequestList/styles'
import { RedAsterisk } from 'containers/FPLRegistration/styles'
import { useIntl } from 'hooks/common'
import { getCreationRecipientFormRules } from './utils';
import { toLower } from 'lodash'
import { isRequiredCity, isRequiredPostCode } from './validatorUtils'

export const RecipientForm = ({ form, country, initFormData }) => {
  const intl = useIntl()

  const rulesByField = useMemo(() => getCreationRecipientFormRules(country, intl), [country,intl])

  const { requiredCity, requiredPostcode } = useMemo(() => {
    const countryLowerCase = toLower(country)
    return {
      requiredCity: isRequiredCity(countryLowerCase),
      requiredPostcode: isRequiredPostCode(countryLowerCase)
    }
  }, [country])

  return (
    <StyledForm>
      <PaddingRow>
        <Col span={12}>
          <StyledFormItem
            label={
              <>
                <T id='recipient_name' />
                <RedAsterisk />
              </>
            }
          >
            {form.getFieldDecorator('name', {
              initialValue: initFormData?.name,
              rules: rulesByField.name
            })(<SmallInput />)}
          </StyledFormItem>
        </Col>
        <Col span={12}>
          <StyledFormItem
            label={
              <>
                <T id='recipient_phone' />
                <RedAsterisk />
              </>
            }
          >
            {form.getFieldDecorator('contact', {
              initialValue: initFormData?.contact,
              rules: rulesByField.phone
            })(<SmallInput />)}
          </StyledFormItem>
        </Col>
        <Col span={16}>
          <StyledFormItem
            label={
              <>
                <T id='recipient_address' />
                <RedAsterisk />
              </>
            }
          >
            {form.getFieldDecorator('address1', {
              initialValue: initFormData?.address1,
              rules: rulesByField.address
            })(<SmallInput />)}
          </StyledFormItem>
        </Col>
        <Col span={4}>
          <StyledFormItem
            label={
              <>
                <T id='city' />
                {requiredCity && <RedAsterisk />}
              </>
            }
          >
            {form.getFieldDecorator('city', {
              initialValue: initFormData?.city,
              rules: rulesByField.city
            })(<SmallInput />)}
          </StyledFormItem>
        </Col>
        <Col span={4}>
          <StyledFormItem
            label={
              <>
                <T id='postcode' />
                {requiredPostcode && <RedAsterisk />}
              </>
            }
          >
            {form.getFieldDecorator('postcode', {
              initialValue: initFormData?.postcode,
              rules: rulesByField.postcode
            })(<SmallInput />)}
          </StyledFormItem>
        </Col>
      </PaddingRow>
    </StyledForm>
  )
}
