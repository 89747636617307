import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTheme } from 'styled-components'
import {
  StyledButton,
  StyledCol,
  StyledContainer,
  StyledDisabledButton,
  StyledGreetingCol,
  StyledGreetingContainer,
  StyledImg,
  StyledText,
  TextCol
} from './styles'
import { ROUTES } from 'containers/Base/constants'
import { Card } from 'components/Card'
import { Col, Dropdown, Menu, Row, T, Tooltip } from '@nv/react-commons/src/Components'
import { useNavigate } from 'react-router-dom'
import { mpTrackCreatedOrder } from 'containers/FPLMixpanel/helpers'
import { CREATION_SOURCE, PAGE_NAMES } from 'containers/FPLMixpanel/constants'
import { ItemButton } from 'containers/FPLOrderCreate/styles'
import Config from 'configs'
import { Text } from 'components/Text'

export const ORDER_CREATION_TYPE = {
  E2E: 'E2E',
  B2B: 'B2B'
}

const HomeCard = ({ theme: { mascotInternational }, isSelfServe, country }) => {
  const navigate = useNavigate()

  const isSelfServeShipper = useMemo(() => {
    if (!isSelfServe) return false
    const selfServeCountries = Config.FPL_AVAILABLE_SELF_SERVE_COUNTRIES?.split(/,\s*/) || []
    return !selfServeCountries.includes(country) && isSelfServe
  }, [country, isSelfServe])

  const trackCreateOrder = (pageName, orderType) => {
    mpTrackCreatedOrder({ pageName, source: CREATION_SOURCE.XB_DASH, orderType })
  }

  const handleTrackParcels = () => {
    navigate(ROUTES.FPL_TRACKING)
  }

  const navigateOrderPage = type => {
    const pathName = type.key === ORDER_CREATION_TYPE.E2E ? ROUTES.FPL_OC : ROUTES.FPL_MMCC_ORDER_CREATION
    navigate(pathName)
    trackCreateOrder(PAGE_NAMES[pathName], type.key)
  }

  const renderOptions = () => {
    return (
      <Menu onClick={navigateOrderPage}>
        <Menu.Item key={ORDER_CREATION_TYPE.E2E}>
          <ItemButton>
            <Text id='create_int_b2c_order' style={{ fontWeight: 'bold' }} />
            <ul>
              <li>
                ◦ <T id='e2e_order' />
              </li>
            </ul>
          </ItemButton>
        </Menu.Item>
        <Menu.Item key={ORDER_CREATION_TYPE.B2B}>
          <ItemButton>
            <Text id='create_int_b2b_order' style={{ fontWeight: 'bold' }} />
            <ul>
              <li>
                ◦ <T id='mmcc_order_type' />
              </li>
              <li>
                ◦ <T id='b2b_order_type' />
              </li>
            </ul>
          </ItemButton>
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <StyledContainer>
      <Card withBanner>
        <Row type='flex' align='middle' gutter={[0, { xs: 8, sm: 8, md: 0 }]}>
          <StyledCol xs={{ span: 24 }} md={{ span: 4, order: 0 }} textAlign='center'>
            <StyledImg width={80} height={80} src={mascotInternational} />
          </StyledCol>
          <Col xs={{ span: 24 }} md={{ span: 12, order: 1 }} xl={{ span: 9 }}>
            <StyledGreetingContainer>
              <StyledGreetingCol>
                <T id='greetings' />
              </StyledGreetingCol>
              <TextCol>
                <StyledText id='international_home_greeting' />
              </TextCol>
            </StyledGreetingContainer>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 8, order: 2 }} xl={{ span: 11 }}>
            <Row type='flex' gutter={[16, 8]} justify='center'>
              <StyledCol xl={{ span: 12 }} xs={{ span: 24 }} textAlign='right'>
                {isSelfServeShipper ? (
                  <Tooltip title={<T id='international_unavailable_for_lite_account' />} placement='top'>
                    <StyledDisabledButton type='primary'>
                      <T id='create_order' />
                    </StyledDisabledButton>
                  </Tooltip>
                ) : (
                  <StyledButton type='primary'>
                    <Dropdown
                      overlay={renderOptions()}
                      placement='bottomRight'
                      trigger={['click']}
                      getPopupContainer={trigger => trigger}
                    >
                      <a data-analyticsid='orderCreationType'>
                        <T id='create_order' />
                      </a>
                    </Dropdown>
                  </StyledButton>
                )}
              </StyledCol>
              <Col xl={{ span: 12 }} xs={{ span: 24 }}>
                <StyledButton type='secondary' data-analyticsid='4plTrackParcels' onClick={handleTrackParcels}>
                  <T id='track_parcels' allCaps />
                </StyledButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </StyledContainer>
  )
}

HomeCard.propTypes = {
  theme: PropTypes.object,
  isSelfServe: PropTypes.bool,
  country: PropTypes.string
}

const _HomeCard = compose(withTheme)(HomeCard)
export { _HomeCard as HomeCard }
