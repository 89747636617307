import React, { useCallback, useContext, useState, useMemo, useEffect } from 'react'
import { Address, TimeSlotProps } from './types'
import { StyledCustomCard, VspaceWrapper } from 'containers/FPLOrderCreate/styles'
import { AddressSelection } from './AddressSelection'
import { SERVICE_SUB_STEPS } from './constants'
import { OCSubStep } from 'components/OCSubStep'
import { PickupType } from './PickupType'
import { PICKUP_TYPES } from 'containers/PickupType/constants'
import { ServiceSelection } from './ServiceSelection'
import { CustomsCurrency } from '../FPLOrderCreate/CustomsCurrency'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { TimeSlot } from './TimeSlot'
import { COUNTRIES } from '@nv/react-commons/src/Constants/Country'
import { createAddress, getShipperAddresses } from 'services/api/fplApi'
import { message } from 'antd'
import { useIntl } from 'hooks/common'
import { lowerCase, toUpper } from 'lodash'
import { sortListWithSelectedFirst } from './utils'
import { SERVICE_TYPE } from 'containers/FPLOrderCreate/constants'

interface State {
  currentSubStep?: string
  senderAddresses: Address[]
  pickupAddresses: Address[]
  activeSenderAddress?: Address
  activePickupAddress?: Address
  fetchingAddress: boolean
  addingAddress: boolean
}

const initialState: State = {
  currentSubStep: undefined,
  senderAddresses: [],
  pickupAddresses: [],
  activeSenderAddress: undefined,
  activePickupAddress: undefined,
  fetchingAddress: false,
  addingAddress: false
}

const ServiceDetailsStep = () => {
  const [state, setState] = useState<State>(initialState)
  const intl = useIntl()
  const { currentSubStep, activeSenderAddress, activePickupAddress, fetchingAddress, addingAddress } = state
  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const {
    senderAddress,
    pickupAddress,
    pickupType,
    timeSlot,
    timeSlots = [],
    customsCurrency,
    selectedService,
    addresses,
    mountedAddress
  } = ocState

  const { origin_country: originCountry, destination_country: destinationCountry } = selectedService

  const intraAseanOriginalCountryCode = useMemo(() => {
    // Hardcoded using SG country code instead of CN because of Domestic does not allow add new address outside Asia
    const isIntraAsean = Object.values(COUNTRIES).includes(lowerCase(originCountry))
    return isIntraAsean ? originCountry : toUpper(COUNTRIES.SG)
  }, [originCountry, destinationCountry])

  const allowPickupTypeChange = useMemo(() => {
    if (selectedService.type === SERVICE_TYPE.B2B_BUNDLE) {
      return Object.values(COUNTRIES).includes(lowerCase(originCountry))
    }
    return true
  }, [originCountry, selectedService])

  const getStatusOfStep = useCallback(
    step => {
      return currentSubStep === step ? OCSubStep.OPEN : OCSubStep.CLOSE
    },
    [currentSubStep]
  )

  const fetchListAddresses = async () => {
    setState(prevState => ({ ...prevState, fetchingAddress: true }))
    const res = await getShipperAddresses()
    if (res?.ok) {
      updateOCState({ addresses: res.data })
    }
    setState(prevState => ({ ...prevState, fetchingAddress: false }))
  }

  useEffect(() => {
    if (!senderAddress && !mountedAddress) {
      fetchListAddresses()
      updateOCState({ mountedAddress: true })
    }
    if (addresses.length && !senderAddress && !pickupAddress) {
      const filteredAddresses = addresses.filter(address => address.country === intraAseanOriginalCountryCode)
      if (filteredAddresses.length) {
        const defaultFirstAddress = filteredAddresses[0]
        updateOCState({ senderAddress: defaultFirstAddress, pickupAddress: defaultFirstAddress })
        setState(prevState => ({
          ...prevState,
          activeSenderAddress: defaultFirstAddress,
          activePickupAddress: defaultFirstAddress
        }))
      }
    }
  }, [addresses, senderAddress, pickupAddress, selectedService, mountedAddress])

  useEffect(() => {
    if (senderAddress) {
      setState(prevState => ({
        ...prevState,
        activeSenderAddress: senderAddress
      }))
    }
    if (pickupAddress) {
      setState(prevState => ({
        ...prevState,
        activePickupAddress: pickupAddress
      }))
    }
  }, [senderAddress, pickupAddress])

  const senderAddresses = useMemo(() => {
    const filteredAddresses = addresses.filter(address => address.country === intraAseanOriginalCountryCode)
    return sortListWithSelectedFirst(filteredAddresses, activeSenderAddress)
  }, [addresses, activeSenderAddress])

  const pickupAddresses = useMemo(() => {
    const filteredAddresses = addresses.filter(address => address.country === intraAseanOriginalCountryCode)
    return sortListWithSelectedFirst(filteredAddresses, activePickupAddress)
  }, [addresses, activePickupAddress])

  const cancelEdit = () => {
    setState(preState => ({ ...preState, currentSubStep: undefined }))
  }

  const editSubStep = (type: string) => {
    setState(preState => ({ ...preState, currentSubStep: type }))
  }

  const closeSubStep = () => {
    setState(prevState => ({ ...prevState, currentSubStep: OCSubStep.CLOSE }))
  }

  const getFieldByType = type => {
    let fieldName = ''
    let activeFieldName = ''
    switch (type) {
      case SERVICE_SUB_STEPS.SENDER_ADDRESS:
        fieldName = 'senderAddress'
        activeFieldName = 'activeSenderAddress'
        break
      case SERVICE_SUB_STEPS.PICKUP_ADDRESS:
        fieldName = 'pickupAddress'
        activeFieldName = 'activePickupAddress'
        break
    }
    return {
      fieldName,
      activeFieldName
    }
  }

  const handleChangeSelectedAddress = (selectedAddress: Address, type) => {
    const { fieldName, activeFieldName } = getFieldByType(type)
    updateOCState({ [fieldName]: selectedAddress })
    setState(prevState => ({ ...prevState, [activeFieldName]: selectedAddress }))
    closeSubStep()
  }

  const editTimeslot = (editedTimeslot: TimeSlotProps) => {
    const updatedTimeslots = timeSlots.map(slot => {
      if (slot.id === editedTimeslot.id) {
        return editedTimeslot
      }
      return slot
    })
    let updatedTimeslot = timeSlot
    if (editedTimeslot.id === timeSlot?.id) {
      updatedTimeslot = editedTimeslot
    }
    updateOCState({
      timeSlot: updatedTimeslot,
      timeSlots: updatedTimeslots
    })
  }

  const changeTimeslot = (timeslot: TimeSlotProps) => {
    updateOCState({ timeSlot: timeslot })
    closeSubStep()
  }

  const deleteTimeslot = (deletedTimeslot: TimeSlotProps) => {
    let updatedTimeslot = timeSlot
    const updatedTimeslots = timeSlots.filter(slot => slot.id !== deletedTimeslot.id)
    if (deletedTimeslot.id === timeSlot?.id) {
      updatedTimeslot = undefined
    }

    updateOCState({
      timeSlot: updatedTimeslot,
      timeSlots: updatedTimeslots
    })
  }

  const addNewAddress = async (data, type) => {
    setState(prevState => ({ ...prevState, addingAddress: true }))
    const res = await createAddress(data)
    if (res?.ok) {
      message.success(intl.formatMessage({ id: 'types.success.address_created' }))
      fetchListAddresses()
      const { activeFieldName } = getFieldByType(type)
      setState(prevState => ({ ...prevState, [activeFieldName]: res.data }))
    }
    setState(prevState => ({ ...prevState, addingAddress: false }))
  }

  const cancelChangeAddress = type => {
    const { fieldName, activeFieldName } = getFieldByType(type)
    setState(prevState => ({ ...prevState, [activeFieldName]: ocState[fieldName] }))
    cancelEdit()
  }

  const changeCurrency = value => {
    updateOCState({
      customsCurrency: value
    })
  }

  return (
    <>
      <StyledCustomCard hoverable={false}>
        <ServiceSelection
          status={getStatusOfStep(SERVICE_SUB_STEPS.SERVICE)}
          onEdit={() => editSubStep(SERVICE_SUB_STEPS.SERVICE)}
          onCancel={cancelEdit}
          onSave={closeSubStep}
        />
        <VspaceWrapper height={16} />
        <AddressSelection
          title='sender_info'
          country={intraAseanOriginalCountryCode}
          selectedAddress={senderAddress}
          activeAddress={activeSenderAddress}
          addresses={senderAddresses}
          addingAddress={addingAddress}
          status={getStatusOfStep(SERVICE_SUB_STEPS.SENDER_ADDRESS)}
          onCancel={() => cancelChangeAddress(SERVICE_SUB_STEPS.SENDER_ADDRESS)}
          onEdit={() => editSubStep(SERVICE_SUB_STEPS.SENDER_ADDRESS)}
          fetchingAddress={fetchingAddress}
          onAddAddress={(data: Address) => addNewAddress(data, SERVICE_SUB_STEPS.SENDER_ADDRESS)}
          onChangeSelectedAddress={(data: Address) =>
            handleChangeSelectedAddress(data, SERVICE_SUB_STEPS.SENDER_ADDRESS)
          }
        />
        <VspaceWrapper height={16} />
        <PickupType
          status={getStatusOfStep(SERVICE_SUB_STEPS.PICKUP_TYPE)}
          onCancel={cancelEdit}
          onEdit={() => editSubStep(SERVICE_SUB_STEPS.PICKUP_TYPE)}
          onSave={data => {
            closeSubStep()
            updateOCState({ pickupType: data })
          }}
          pickupType={pickupType}
          allowPickupChange={allowPickupTypeChange}
        />
        {ocState.pickupType === PICKUP_TYPES.SCHEDULED && (
          <>
            <VspaceWrapper height={16} />
            <AddressSelection
              title='pickup.pickup_address'
              country={intraAseanOriginalCountryCode}
              selectedAddress={pickupAddress}
              activeAddress={activePickupAddress}
              addresses={pickupAddresses}
              fetchingAddress={fetchingAddress}
              status={getStatusOfStep(SERVICE_SUB_STEPS.PICKUP_ADDRESS)}
              onCancel={() => cancelChangeAddress(SERVICE_SUB_STEPS.PICKUP_ADDRESS)}
              onEdit={() => editSubStep(SERVICE_SUB_STEPS.PICKUP_ADDRESS)}
              onAddAddress={(data: Address) => addNewAddress(data, SERVICE_SUB_STEPS.PICKUP_ADDRESS)}
              onChangeSelectedAddress={(data: Address) =>
                handleChangeSelectedAddress(data, SERVICE_SUB_STEPS.PICKUP_ADDRESS)
              }
            />
            <VspaceWrapper height={16} />
            <TimeSlot
              selectedTimeslot={timeSlot}
              timeslots={timeSlots}
              country={originCountry}
              status={getStatusOfStep(SERVICE_SUB_STEPS.TIME)}
              onEdit={() => editSubStep(SERVICE_SUB_STEPS.TIME)}
              onCancel={cancelEdit}
              onAddTimeslot={(data: TimeSlotProps) => {
                updateOCState({ timeSlots: [...timeSlots, data] })
              }}
              onChangeTimeslot={changeTimeslot}
              onDeleteTimeslot={deleteTimeslot}
              onEditTimeslot={data => editTimeslot(data)}
            />
          </>
        )}
        <VspaceWrapper height={16} />
        {customsCurrency && (
          <CustomsCurrency
            country={destinationCountry}
            currency={customsCurrency}
            onChange={data => changeCurrency(data)}
          />
        )}
      </StyledCustomCard>
    </>
  )
}

export { ServiceDetailsStep }
