import { createContext } from 'react'
import { MMCCOrderCreationState, Service } from './types'

interface MMCCOrderCreationContextType {
  ocState: MMCCOrderCreationState
  updateService: (newService: Service) => void
  updateOCState: (newState: Partial<MMCCOrderCreationState>) => void
}

export const MMCCOrderCreationContext = createContext<MMCCOrderCreationContextType>({
  ocState: null,
  updateService: () => null,
  updateOCState: () => null
})
