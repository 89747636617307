import React from 'react'
import { compose } from 'redux'
import { mapValues } from 'lodash'

import { Form, Col } from '@nv/react-commons/src/Components'
import { useIntl } from 'hooks/common'
import { RowStyled } from './styles'

const OCForm = ({ form, formFields, country, inactiveBundles, customsCurrency }) => {
  const intl = useIntl()

  const renderFieldItem = (config, index) => {
    const [Component, lg, title, isRequired, validationRules] = config
    if (!Component) {
      return <Col lg={lg} span={lg && 24} key={index}></Col>
    }
    return (
      <Col lg={lg} span={lg && 24} key={index}>
        <Component
          form={form}
          intl={intl}
          title={title}
          isRequired={isRequired}
          country={country}
          validationRules={validationRules}
          bundles={inactiveBundles}
          customsCurrency={customsCurrency}
        />
      </Col>
    )
  }

  return (
    <Form small>
      {Object.keys(formFields).map((key, idx) => (
        <RowStyled background={idx % 2 === 0 ? 'transparent' : '#F7F9FA'} type='flex' key={key}>
          {formFields[key].map(renderFieldItem)}
        </RowStyled>
      ))}
    </Form>
  )
}

const OrderCreationForm = compose(
  Form.create({
    mapPropsToFields: ({ dataSource }) => {
      return mapValues(dataSource, value => Form.createFormField({ value }))
    },
    onValuesChange: (props, values) => {
      props.onChange(values)
    }
  })
)(OCForm)

export { OrderCreationForm }
