import React, { useContext } from 'react'
import { MMCCOrderCreationContext } from './MMCCOrderCreationContext'
import { MMCCKeyboard } from './MMCCKeyboard'
import { SERVICE_TYPE } from 'containers/FPLOrderCreate/constants'
import { B2BBundleKeyboard } from './B2BBundleKeyboard'

export const ManualKeyboard = () => {
  const { ocState, updateOCState } = useContext(MMCCOrderCreationContext)
  const { activeGroupId, mmccB2BFormGroup, selectedService } = ocState

  const changeMethod = method => {
    updateOCState({ ocMethod: method })
  }

  if (selectedService.type === SERVICE_TYPE.B2B_BUNDLE) {
    return <B2BBundleKeyboard />
  }

  return <MMCCKeyboard
    onMethodChange={method => changeMethod(method)}
    activeGroupId={activeGroupId}
    orderFormGroup={mmccB2BFormGroup}
    updateOCState={updateOCState}
  />
}
